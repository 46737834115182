import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from '../utils/LinkResolver'
import { Container } from 'react-bootstrap'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import { SliceZone } from '../components/SliceZone'

const PageTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicPage
  const page = pageContent.data || {}

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicMenuLink || {}
  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <Seo title={page.page_title.text} lang={lang} />
      <Container className="single-page_wrapper">
        <h1 className="page-title">{page.page_title.text}</h1>
        <SliceZone sliceZone={page.body} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        page_title {
          text
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...PageDataBodyTable
          ...PageDataBodyText
          ...PageDataBodyQuote
          ...PageDataBodyFullWidthImage
          ...PageDataBodyImageGallery
          ...PageDataBodyImageHighlight
          ...PageDataBodyListWithTwoColumns
        }
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`

export default withPrismicPreview(PageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
