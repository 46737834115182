import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { CustomLink } from '../../utils/CustomLink'

export const ListWithTwoColumns = ({ slice }) => {
  var image = false
  return (
    <section className="content-section listWithTwoColumns">
      <div className="container-fluid card columns-list">
        {slice &&
          slice.items.map((field) => (
            <div className="d-flex justify-content-around">
              {image && <img src="https://picsum.photos/100" />}
              {field.leftfield && (
                <span className="columns-list-item first-item">
                  {field.leftfield}
                </span>
              )}
              {image && <img src="https://picsum.photos/100" />}
              {field.rightfield && (
                <span className="columns-list-item first-item">
                  {field.rightfield}
                </span>
              )}
            </div>
          ))}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyListWithTwoColumns on PrismicPageDataBodyListWithTwoColumns {
    items {
      leftfield
      rightfield
    }
  }
`
//   fragment NewsDataBodyListWithTwoColumns on PrismicNewsDataBodyListWithTwoColumns {
//     primary {
//       listWithTwoColumns {
//         raw
//       }
//     }
//   }
//
// fragment HomepageDataBodylistWithTwoColumns on PrismicHomepageDataBodylistWithTwoColumns {
//   primary {
//     listWithTwoColumns {
//       raw
//     }
//   }
// }
